import React from "react"
import { Layout } from "@solublestudio/gatsby-theme-soluble-source"
import { FormSection } from "@solublestudio/bdeo-design-system"

export default function Resource({ data, pageContext }) {
  return (
    <Layout
      navbarFixed={true}
      navbarTransparent={true}
      isDark={false}
      footerExtraParams={{
        alternateLanguages: pageContext.seo?.alternateLanguages,
        hideNavs: true,
      }}
      headerExtraParams={{
        hideNavs: true,
      }}
    >
      {data.page.sections?.length ? (
        <FormSection
          {...data.page.sections[0]}
          parentPage={data.page.parentPage}
          titleGradient={
            data.page.sections[0].titleGradient || {
              titleTag: "h1",
              title: data.page.title,
            }
          }
          subtitle={data.page.sections[0].subtitle || data.page.description}
        />
      ) : null}
    </Layout>
  )
}
