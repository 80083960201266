import { graphql } from "gatsby"
import Resource from "./resource"

export default Resource

export const query = graphql`
  fragment SuccessStoryItem on DatoCmsSuccessStory {
    title
    slug
    description
    image {
      file {
        publicURL
        childImageSharp {
          resize(width: 300) {
            src
          }
        }
      }
    }
  }

  query SuccessStory($id: String, $language: String) {
    page: datoCmsSuccessStory(id: { eq: $id }, locale: { eq: $language }) {
      title
      slug
      description
      parentPage {
        title
        slug
      }
      sections {
        __typename
        ... on DatoCmsFormSection {
          ...DatoCmsFormSection
        }
      }
    }
  }
`
